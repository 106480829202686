import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {graphql, Link} from "gatsby";

const description = `
Rinat Silnov's blog archive.
An articles about JavaScript, AngularJS, CSS, Yii and other web development topics
`;

const keywords = ['Blog', 'Archive', 'Frontend Developer', 'AngularJS', 'JavaScript', 'Yii', 'Rinat Silnov', 'rinatio'];


const Archive = ({ data }) => {
    const { allMdx: { edges }} = data;
    const posts = edges.map(edge => edge.node.frontmatter);

    return <Layout>
        <SEO title="Blog Archive - Rinat Silnov" description={description} keywords={keywords}/>
        <section className="post-list">
            <header>
                <h1>Archive</h1>
            </header>
            {posts.map(post =>
                <div className="post-card" key={post.slug}>
                    <h3>
                        <Link to={post.slug}>
                            {post.title}
                        </Link>
                    </h3>
                    <div className="description">
                        {post.description}
                    </div>
                    <div className="post-tags">
                        {post['tags'].map(tag => (
                            <span key={tag}>
                                <span className="post-tags__tag">{tag}</span>
                                {' '}
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </section>
    </Layout>
};

export default Archive

export const pageQuery = graphql`{
  allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
    edges {
      node {
        frontmatter {
          date,
          slug
          title,
          tags,
          description
        }
      }
    }
  }
}`;